import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails"

export default class extends Controller {
  static values = {channel: String}

  connect() {
    console.log("connected")
  }

  change(event) {
    const title_id = event.target.value

    fetch(`/fast/channels/${this.channelValue}/titles/${title_id}/metadata`, {
      headers: {
        accept: "text/vnd.turbo-stream.html",
      }
    }).then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html);
      })
  }
}