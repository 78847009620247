import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this._init();
  }

  _init() {
    $(this.element).bootstrapSwitch();
  }
}
