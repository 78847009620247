import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        url: String,
        countries: Boolean
    }

    connect() {
        if (this.countriesValue) {
            this._initCountrySelect2();
        } else if (this.hasUrlValue) {
            this._initUrlSelect2();
        } else {
            this._init();
        }

        $(this.element).on('select2:select', () => {
            const event = new Event('change', {bubbles: true});
            this.element.dispatchEvent(event);
        })
    }

    disconnect() {
        $(this.element).select2('destroy');
    }

    _init() {
        $(this.element).select2({
            width: '100%',
            language: 'de'
        });
    }

    _initCountrySelect2() {
        $(this.element).select2({
            language: "de",
            width: '100%',
            templateSelection: function (option) {
                if (!option.id) {
                    return option.text;
                }
                if (!option.element.dataset.nameShort) {
                    return option.text;
                }
                return option.element.dataset.nameShort;
            }
        })
    }

    _initUrlSelect2() {
        $(this.element).select2({
            width: '100%',
            language: 'de',
            minimumInputLength: 1,
            ajax: {
                url: this.urlValue,
                dataType: 'json',
                delay: 100,
                cache: true
            }
        });
    }
}