class ReleaseAvailsList {
  constructor(translations) {
    this.translations = translations
    this.table = $('#release-avails-list')
    this.price_tiers = this.table.attr('data-price-tiers')
    this.internal_order = this.table.attr('data-internal-order')
    this.episodes = this.table.attr('data-episodes')
    this.detailRows = []
    this.order = [
      [2, 'asc'],
      [6, 'asc'],
    ];
    this.checkboxIndex = this.episodes === "1" ? 1 : 0;

    this.columnDefs = [
      {
        targets: 0,
        orderable: false,
        visible: this.episodes === "1",
      },
      {
        targets: 1,
        orderable: false,
      },
      {
        targets: 25,
        orderable: true,
        visible: this.price_tiers === "1",
      },
      {
        targets: 26,
        orderable: true,
        visible: this.price_tiers === "1",
      },
      {
        targets: 27,
        orderable: true,
        visible: this.price_tiers === "1",
      },
      {
        targets: 28,
        orderable: true,
        visible: this.price_tiers === "1",
      },
      {
        targets: 30,
        orderable: true,
        visible: this.internal_order === "1",
      },
      {
        targets: -1,
        orderable: false,
      }
    ];
  }

  init() {
    if (!this.table.length) {
      return null;
    }

    let self = this;

    let options = {
      // DOM Layout settings
      dom: `<'row'<'col-sm-12'tr>>
    		<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

      // Order settings
      order: this.order,
      autoWidth: false,
      bSortCellsTop: true,
      scrollX: true,
      bProcessing: true,
      bServerSide: true,
      sAjaxSource: this.table.data('source'),
      fnServerParams: function (aoData) {
        self.pushSearchData(aoData)
      },

      headerCallback: function (thead, data, start, end, display) {
        thead.getElementsByTagName('th')[self.checkboxIndex].innerHTML = `
                    <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid">
                        <input type="checkbox" value="" class="kt-group-checkable">
                        <span></span>
                    </label>`;
      },

      lengthMenu: [10, 25, 50, 100],
      pageLength: 25,
      paging: true,

      columnDefs: this.columnDefs,

      language: this.translations,


      "drawCallback": function () {
        setTimeout(function () {
          $('.dataTables_scrollBody thead tr#filter-row').remove()
        }, 1000)

        $.each(self.detailRows, function (i, id) {
          $('#' + id + ' .btn-show-childs').trigger('click')
        })

                KTApp.init(KTAppOptions)


                const data = table.rows().data();
                const saleIds = data.map(function (value, index) {
                    return value.saleID;
                })

                $('a[href*=xlsx]').each(function () {
                  $(this).attr('href', updateURLParameter($(this).attr('href'), "release_avails_list[sales][ids]", saleIds.join(",")))
                })

            },
            "initComplete": function () {
                setTimeout(function () {
                    $('.dataTables_scrollBody thead tr#filter-row').remove()
                }, 1000)
            },
            "rowCallback": function () {
                setTimeout(function () {
                    $('.dataTables_scrollBody thead tr#filter-row').remove()
                }, 1000)
            },
            createdRow: function (row, data) {
                $(row).attr('data-child', data.DT_ChildRows)
            }
        }

    let table = this.table.DataTable(options);

    $('#filter-row button[data-behavior=search]').on('click', function () {
      self.searchColumns(table)
    });

    $('#filter-row input, #filter-row select').on('keypress', function (event) {
      if (event.keyCode === 13) {
        self.searchColumns(table)
      }
    })

    $('#filter-row button[data-behavior=reset]').on('click', function (e) {
      e.preventDefault();
      document.querySelector('.inline-form').reset();
      self.searchColumns(table)
    });

    let $body = $('body');

    $body.on('change', '.kt-group-checkable', function () {
      var set = $(this).closest('.dataTables_wrapper').find('td .kt-checkable');
      var checked = $(this).is(':checked');

      $(set).each(function () {
        if (checked) {
          $(this).prop('checked', true).change();
          $(this).closest('tr').addClass('active');
        } else {
          $(this).prop('checked', false).change();
          $(this).closest('tr').removeClass('active');
        }
      });
    });

    $('#release-avails-list_wrapper').on('change', 'tbody tr .kt-checkbox', function () {
      $(this).parents('tr').toggleClass('active');

      self.checkAtLeastOneChecked(this)
    });

    $('#release-avails-list-bulk-update').on('click', function () {
      let $bulkUpdateForm = $('#bulk-update-modal form');

      let name = $('#release-avails-list input.kt-checkable').attr("name")

      $bulkUpdateForm
        .find('input[name="' + name + '"]')
        .remove();

      $('form.inline-form').serializeArray().forEach(function (item) {
        if (item.name === name) {
          $bulkUpdateForm
            .prepend('<input type="hidden" name="' + item.name + '" value="' + item.value + '"/>')
        }
      })
    });

    self.table.on('click', '.btn-show-childs', function () {
      let tr = $(this).closest('tr')
      let row = table.row(tr)
      let idx = $.inArray(tr.attr('id'), self.detailRows)

      if (row.child.isShown()) {
        tr.attr('data-shown', false)
        row.child.hide()

        self.detailRows.splice(idx, 1)
      } else {
        tr.attr('data-shown', true)
        row.child(tvChildRowFormat(JSON.parse($(tr).attr('data-child')), tr, self.table)).show()

        if (idx === -1) {
          self.detailRows.push(tr.attr('id'))
        }
      }

      $.fn.dataTable
        .tables({visible: true, api: true})
        .columns.adjust()
    })

    $('#itunes-movie-modal .btn-primary,' +
      '#itunes-season-modal .btn-primary,' +
      '#invalid-titles-itunes-movie-modal .btn-outline-brand,' +
      '#invalid-titles-itunes-tv-modal .btn-outline-brand').on('click', function () {
      $(this).closest('.modal').modal('hide');
    });

  }

    searchColumns(table) {
        $('#filter-row th').each(function (i) {
            let value = $(this).find('input, select').val() || ""
            if (table.column(i).length && table.column(i).search() !== value) {
                table
                    .column(i)
                    .search(value)
                    .draw();
            }
        });
    }


  checkAtLeastOneChecked(checkbox) {
    if ($(checkbox).closest('.dataTables_wrapper').find('td .kt-checkable:checked').length > 0) {
      $('#release-avails-list-bulk-update').prop('disabled', false)
    } else {
      $('#release-avails-list-bulk-update').prop('disabled', true)
    }
  }


  pushSearchData(aoData) {
    aoData.push({"name": "sSearch_4", "value": $('#est_gteq').val() + "-" + $('#est_lteq').val()});
    aoData.push({"name": "sSearch_5", "value": $('#tvod_gteq').val() + "-" + $('#tvod_lteq').val()});
    aoData.push({"name": "sSearch_18", "value": $('#physical_gteq').val() + "-" + $('#physical_lteq').val()});
    aoData.push({"name": "sSearch_22", "value": $('#end_gteq').val() + "-" + $('#end_lteq').val()});
  }
}

export default ReleaseAvailsList