class EmaListMovie {
  constructor(translations) {
    this.translations = translations
    this.table = $('#ema-list')
    this.detailRows = []

    if (this.table.data('ema-type') === "movie") {
      this.order = [
        [6, 'asc'],
        [8, 'asc'],
        [10, 'asc'],
        [4, 'asc'],
      ];

      this.columnDefs = [
        {
          targets: 0,
          width: '30px',
          className: 'dt-right',
          orderable: false,
          data: "id"
        },
        {
          targets: 1,
          orderable: false,
          data: "select_status",
        },
        {
          targets: 2,
          data: "DisplayName"
        },
        {
          targets: 3,
          data: "AssetLanguage"
        },
        {
          targets: 4,
          data: "Territory"
        },
        {
          targets: 5,
          data: "WorkType"
        },
        {
          targets: 6,
          data: "TitleDisplayUnlimited"
        },
        {
          targets: 7,
          data: "LocalizationType"
        },
        {
          targets: 8,
          data: "GroupIdentity"
        },
        {
          targets: 9,
          data: "LicenseType"
        },
        {
          targets: 10,
          data: "LicenseRightsDescription"
        },
        {
          targets: 11,
          data: "FormatProfile"
        },
        {
          targets: 12,
          data: "Start"
        },
        {
          targets: 13,
          data: "End"
        },
        {
          targets: 14,
          data: "PriceType"
        },
        {
          targets: 15,
          data: "PriceValue"
        },
        {
          targets: 16,
          data: "PriceCurrency"
        },
        {
          targets: 17,
          data: "AltID"
        },
        {
          targets: 18,
          data: "ContentID"
        },
        {
          targets: 19,
          data: "ReportingID"
        },
        {
          targets: 20,
          data: "ALID"
        },
        {
          targets: 21,
          data: "SuppressionLiftDate"
        },
        {
          targets: 22,
          data: "ReleaseYear"
        },
        {
          targets: 23,
          data: "ReleaseHistoryOriginal"
        },
        {
          targets: 24,
          data: "ReleaseHistoryPhysicalHV"
        },
        {
          targets: 25,
          data: "RatingValue"
        },
        {
          targets: 26,
          data: "Any"
        },
        {
          targets: 27,
          data: "ServiceProvider"
        },
        {
          targets: -1,
          orderable: false,
          data: "actions"
        }
      ];
    } else {
      this.order = [
        [7, 'asc'],
        [16, 'asc'],
        [5, 'asc'],
        [18, 'asc'],
      ];

      this.columnDefs = [
        {
          targets: 0,
          width: '30px',
          className: 'dt-right',
          orderable: false,
          data: "id"
        },
        {
          targets: 1,
          data: "collapse",
          orderable: false,
          sortable: false
        },
        {
          targets: 2,
          data: "select_status"
        },
        {
          targets: 3,
          data: "DisplayName"
        },
        {
          targets: 4,
          data: "AssetLanguage"
        },
        {
          targets: 5,
          data: "Territory"
        },
        {
          targets: 6,
          data: "WorkType"
        },
        {
          targets: 7,
          data: "SeriesTitleDisplayUnlimited"
        },
        {
          targets: 8,
          data: "SeasonNumber"
        },
        {
          targets: 9,
          data: "EpisodeNumber"
        },
        {
          targets: 10,
          data: "LocalizationType"
        },
        {
          targets: 11,
          data: "EpisodeTitleDisplayUnlimited"
        },
        {
          targets: 12,
          data: "EpisodeCount"
        },
        {
          targets: 13,
          data: "SeriesID"
        },
        {
          targets: 14,
          data: "SeasonID"
        },
        {
          targets: 15,
          data: "EpisodeTitleID"
        },
        {
          targets: 16,
          data: "EpisodeID"
        },
        {
          targets: 17,
          data: "GroupIdentity"
        },
        {
          targets: 18,
          data: "LicenseType"
        },
        {
          targets: 19,
          data: "LicenseRightsDescription"
        },
        {
          targets: 20,
          data: "FormatProfile"
        },
        {
          targets: 21,
          data: "Start"
        },
        {
          targets: 22,
          data: "End"
        },
        {
          targets: 23,
          data: "PriceType"
        },
        {
          targets: 24,
          data: "PriceValue"
        },
        {
          targets: 25,
          data: "PriceCurrency"
        },
        {
          targets: 26,
          data: "SuppressionLiftDate"
        },
        {
          targets: 27,
          data: "ReleaseYear"
        },
        {
          targets: 28,
          data: "ReleaseHistoryOriginal"
        },
        {
          targets: 29,
          data: "ReleaseHistoryPhysicalHV"
        },
        {
          targets: 30,
          data: "RatingValue"
        },
        {
          targets: 31,
          data: "Any"
        },
        {
          targets: 32,
          data: "ServiceProvider"
        },
        {
          targets: -1,
          orderable: false,
          data: "actions"
        }
      ];
    }

    this.checkboxIndex = this.table.data('ema-type') === "movie" ? 0 : 1;
  }

  init() {
    if (!this.table.length) {
      return null;
    }

    let self = this;

    this.validateEmaType()

    let options = {
      // DOM Layout settings
      dom: `<'row'<'col-sm-12'tr>>
    		<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

      // Order settings
      order: this.order,
      autoWidth: false,
      bSortCellsTop: true,
      scrollX: true,
      bProcessing: true,
      bServerSide: true,
      sAjaxSource: this.table.data('source'),
      fnServerParams: function (aoData) {
        self.pushSearchData(aoData)
      },

      headerCallback: function (thead, data, start, end, display) {
        thead.getElementsByTagName('th')[self.checkboxIndex].innerHTML = `
                    <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid">
                        <input type="checkbox" value="" class="kt-group-checkable">
                        <span></span>
                    </label>`;
      },

      lengthMenu: [10, 25, 50, 100],
      pageLength: 25,
      paging: true,

      columnDefs: this.columnDefs,

      language: this.translations,


      "drawCallback": function () {
        setTimeout(function () {
          $('.dataTables_scrollBody thead tr#filter-row').remove()
        }, 1000)

        $.each(self.detailRows, function (i, id) {
          $('#' + id + ' .btn-show-childs').trigger('click')
        })
      },
      "initComplete": function () {
        setTimeout(function () {
          $('.dataTables_scrollBody thead tr#filter-row').remove()
        }, 1000)
      },
      "rowCallback": function () {
        setTimeout(function () {
          $('.dataTables_scrollBody thead tr#filter-row').remove()
        }, 1000)
      },
      createdRow: function (row, data) {
        $(row).attr('data-child', data.DT_ChildRows)
      }
    }

    let table = this.table.DataTable(options);

    $('#filter-row button[data-behavior=search]').on('click', function () {
      self.searchColumns(table)
    });

    $('#filter-row input, #filter-row select').on('keypress', function (event) {
      if (event.keyCode === 13) {
        self.searchColumns(table)
      }
    })

    $('#filter-row button[data-behavior=reset]').on('click', function (e) {
      e.preventDefault();
      document.querySelector('.inline-form').reset();
      self.searchColumns(table)
    });

    let $body = $('body');

    $body.on('change', '.kt-group-checkable', function () {
      var set = $(this).closest('.dataTables_wrapper').find('td .kt-checkable');
      var checked = $(this).is(':checked');

      $(set).each(function () {
        if (checked) {
          $(this).prop('checked', true).change();
          $(this).closest('tr').addClass('active');
        } else {
          $(this).prop('checked', false).change();
          $(this).closest('tr').removeClass('active');
        }
      });
    });

    $('#ema-list_wrapper').on('change', 'tbody tr .kt-checkbox', function () {
      $(this).parents('tr').toggleClass('active');

      self.checkAtLeastOneChecked(this)
    });

    $('#ema-list-bulk-update').on('click', function () {
      let $bulkUpdateForm = $('#bulk-update-modal form');

      let name = $('#ema-list input.kt-checkable').attr("name")

      $bulkUpdateForm
        .find('input[name="' + name + '"]')
        .remove();

      $('form.inline-form').serializeArray().forEach(function (item) {
        if (item.name === name) {
          $bulkUpdateForm
            .prepend('<input type="hidden" name="' + item.name + '" value="' + item.value + '"/>')
        }
      })
    });

    if (this.table.data('ema-type') === "tv") {
      self.table.on('click', '.btn-show-childs', function () {
        let tr = $(this).closest('tr')
        let row = table.row(tr)
        let idx = $.inArray(tr.attr('id'), self.detailRows)

        if (row.child.isShown()) {
          tr.attr('data-shown', false)
          row.child.hide()

          self.detailRows.splice(idx, 1)
        } else {
          tr.attr('data-shown', true)
          let childData = JSON.parse($(tr).attr('data-child'))
          childData = childData.map(childRow => {
            // Delete column used for export only
            delete childRow["SRP"]
            return childRow
          })
          row.child(tvChildRowFormat(childData, tr)).show()

          if (idx === -1) {
            self.detailRows.push(tr.attr('id'))
          }
        }

        $.fn.dataTable
          .tables({visible: true, api: true})
          .columns.adjust()
      })
    }
  }

  searchColumns(table) {
    $('#filter-row th').each(function (i) {
      let value = $(this).find('input, select').val() || "";
      let column = table.columns(i);
      if (column && column.search() !== value) {
        column
          .search(value)
          .draw();
      }
    });

    Object.keys(table.ajax.params()).forEach(function (item) {
      let $excel_links = $('a[href*=xlsx]')
      $excel_links.attr('href', updateURLParameter($excel_links.attr('href'), item, table.ajax.params()[item]));
    })
  }

  validateEmaType() {
    $(document).on('changed.bs.select', function (e) {
      let $update = $('#ema_list_ema_type_update')
      let $master = $('#ema_list_ema_type_master')

      if ($("[id^=ema_list_platforms]").data("amazon-ids").includes(parseInt(e.target.value))) {
        $update.prop("checked", true).change()
        $master.closest('.kt-radio').addClass('kt-radio--disabled')
        $master.prop("disabled", true).change()
      } else {
        $master.closest('.kt-radio').removeClass('kt-radio--disabled')
        $master.prop("disabled", false).change()
      }
    })
  }


  checkAtLeastOneChecked(checkbox) {
    if ($(checkbox).closest('.dataTables_wrapper').find('td .kt-checkable:checked').length > 0) {
      $('#ema-list-bulk-update').prop('disabled', false)
    } else {
      $('#ema-list-bulk-update').prop('disabled', true)
    }
  }


  pushSearchData(aoData) {
    if (this.table.data('ema-type') === "movie") {
      aoData.push({"name": "sSearch_11", "value": $('#start_gteq').val() + "-" + $('#start_lteq').val()});
      aoData.push({"name": "sSearch_12", "value": $('#end_gteq').val() + "-" + $('#end_lteq').val()});
      aoData.push({
        "name": "sSearch_20",
        "value": $('#pre_order_gteq').val() + "-" + $('#pre_order_lteq').val()
      });
      aoData.push({
        "name": "sSearch_21",
        "value": $('#release_year_gteq').val() + "-" + $('#release_year_lteq').val()
      });
      aoData.push({
        "name": "sSearch_22",
        "value": $('#original_gteq').val() + "-" + $('#original_lteq').val()
      });
      aoData.push({
        "name": "sSearch_23",
        "value": $('#physical_gteq').val() + "-" + $('#physical_lteq').val()
      });
    } else {
      aoData.push({"name": "sSearch_11", "value": $('#start_gteq').val() + "-" + $('#start_lteq').val()});
    }
  }
}

export default EmaListMovie