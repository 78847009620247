"use strict";

import EmaList from './ema_lists'
import ReleaseAvailsList from "./release_avails_lists";
import SubChannels from "./sub_channels";
import TitleSelectStatuses from "./title_select_statuses";
import TitleDeliveryStatuses from "./title_delivery_statuses";
import TitleStoreCheckStatuses from "./title_store_check_statuses";
import TitleExclusiveStatuses from "./title_exclusive_statuses";
import TimingRights from "./timing_rights";
import TimingPromos from "./timing_promos";
import TimingPreorders from "./timing_preorders";
import FixedColumns from "./fixed_columns";
import DashboardReleases from "./dashboard_releases";
import DashboardEndings from "./dashboard_endings";
import DashboardExclusives from "./dashboard_exclusive";

import PublicTitleExclusiveStatuses from "./public/public_title_exclusive_statuses";

window.tvChildRowFormat = function (data, tr, table = null) {
    let result = ""
    $.each(data, function (i, row) {
        let id = row.DT_RowId !== undefined ? row.DT_RowId : `child_row_${i}`

        result += "<tr id='" + id + "' data-parent='" + tr.attr('id') + "'>"
        for (let key in row) {

            if (table != null && !table.DataTable().column(key).visible()) {
                continue
            }

            if (row.hasOwnProperty(key) && key !== "DT_RowId" && key !== "DT_RowAttr") {
                result += "<td>" + row[key] + "</td>"
            }
        }
        result += "</tr>"
    })
    return $(result)
}

window.updateURLParameter = function (url, param, paramVal) {
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (var i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] != param) {
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }
    var rows_txt = temp + "" + param + "=" + encodeURIComponent(paramVal);
    return baseURL + "?" + newAdditionalURL + rows_txt;
}


var KTDatatablesAdvancedColumnRendering = function () {
    const de = {
        "sEmptyTable": "Keine Daten in der Tabelle vorhanden",
        "sInfo": "_START_ bis _END_ von _TOTAL_ Einträgen",
        "sInfoEmpty": "0 bis 0 von 0 Einträgen",
        "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "_MENU_ Einträge anzeigen",
        "sLoadingRecords": "Wird geladen...",
        "sProcessing": "Bitte warten...",
        "sSearch": "Suchen",
        "sZeroRecords": "Keine Einträge vorhanden.",
        "oPaginate": {
            "sFirst": "Erste",
            "sPrevious": "Zurück",
            "sNext": "Nächste",
            "sLast": "Letzte"
        },
        "oAria": {
            "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
            "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
        }
    }

    var initTable1 = function () {
        let $titles = $('#titles')

        if ($titles.length <= 0) {
            return;
        }


        var table = $titles.DataTable({
            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            processing: true,
            serverSide: true,
            ajax: {
                url: $titles.data('source'),
            },
            columns: [
                {data: 'checkbox'},
                {data: 'title'},
                {data: 'season_nr'},
                {data: 'content_type'},
                {data: 'asset_type'},
                {data: 'licensor'},
                {data: 'genre'},
                {data: 'production_year'},
                {data: 'vendor_id'},
                {data: 'created_at'},
                {data: 'actions'},
            ],

            // Order settings
            order: [[1, 'asc']],

            bSortCellsTop: true,

            headerCallback: function (thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                    <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid">
                        <input type="checkbox" value="" class="kt-group-checkable">
                        <span></span>
                    </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    width: '30px',
                    className: 'dt-right',
                    orderable: false
                },
                {
                    targets: -1,
                    orderable: false,
                }
            ],

            language: de
        });

        $titles.on('change', '.kt-group-checkable', function () {
            var set = $(this).closest('table').find('td:first-child .kt-checkable');
            var checked = $(this).is(':checked');

            $(set).each(function () {
                if (checked) {
                    $(this).prop('checked', true).change();
                    $(this).closest('tr').addClass('active');
                } else {
                    $(this).prop('checked', false).change();
                    $(this).closest('tr').removeClass('active');
                }
            });

            checkAtLeastOneChecked(this)
        });

        $titles.on('change', 'tbody tr .kt-checkbox', function () {
            $(this).parents('tr').toggleClass('active');

            checkAtLeastOneChecked(this)
        });

        var checkAtLeastOneChecked = function (checkbox) {
            if ($(checkbox).closest('table').find('td .kt-checkable:checked').length > 0) {
                $('#sales-sheet-button').prop('disabled', false)
            } else {
                $('#sales-sheet-button').prop('disabled', true)
            }
        }
    };

    var drafts = function () {
        var $drafts = $('#titles-draft')

        if ($drafts.length <= 0) {
            return
        }

        var table = $drafts.DataTable({


            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            processing: true,
            serverSide: true,
            ajax: {
                url: $drafts.data('source'),
            },
            columns: [
                {data: 'checkbox'},
                {data: 'from'},
                {data: 'title'},
                {data: 'season_nr'},
                {data: 'content_type'},
                {data: 'asset_type'},
                {data: 'licensor'},
                {data: 'genre'},
                {data: 'production_year'},
                {data: 'sub_title_count', orderable: false},
                {data: 'updated_at'},
                {data: 'created_at'},
                {data: 'actions'},
            ],

            // Order settings
            order: [[2, 'asc']],

            bSortCellsTop: true,

            headerCallback: function (thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                    <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid">
                        <input type="checkbox" value="" class="kt-group-checkable" data-selectable-row-target="checkboxAll" data-action="selectable-row#toggle selectable-row#refresh">
                        <span></span>
                    </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    width: '30px',
                    className: 'dt-right',
                    orderable: false
                },
                {
                    targets: -1,
                    orderable: false,
                }
            ],

            language: de
        });

        $drafts.on('change', '.kt-group-checkable', function () {
            var set = $(this).closest('table').find('td:first-child .kt-checkable');
            var checked = $(this).is(':checked');

            $(set).each(function () {
                if (checked) {
                    $(this).prop('checked', true).change();
                    $(this).closest('tr').addClass('active');
                } else {
                    $(this).prop('checked', false).change();
                    $(this).closest('tr').removeClass('active');
                }
            });

            checkAtLeastOneChecked(this)
        });

        $drafts.on('change', 'tbody tr .kt-checkbox', function () {
            $(this).parents('tr').toggleClass('active');

            checkAtLeastOneChecked(this)
        });

        var checkAtLeastOneChecked = function (checkbox) {
            if ($(checkbox).closest('table').find('td .kt-checkable:checked').length > 0) {
                $('#sales-sheet-button').prop('disabled', false)
            } else {
                $('#sales-sheet-button').prop('disabled', true)
            }
        }
    };


    var initTable2 = function () {

        if ($('#asset_types').length <= 0) {
            return;
        }

        var table = $('#asset_types').DataTable({


            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            order: [[1, 'asc']],

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            language: de,

            bSortCellsTop: true,

            columnDefs: [
                {
                    targets: -1,
                    orderable: false,
                }
            ],
        });

    };

    var initTable3 = function () {

        if ($('#sub_titles').length <= 0) {
            return;
        }

        var table = $('#sub_titles').DataTable({


            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            order: [[1, 'asc']],

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            bSortCellsTop: true,

            columnDefs: [
                {
                    targets: -1,
                    orderable: false,
                }
            ],

            language: de
        });

    };

    var initTable4 = function () {

        if ($('#options').length <= 0) {
            return;
        }

        var table = $('#options').DataTable({


            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            order: [[1, 'asc']],

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            bSortCellsTop: true,

            columnDefs: [
                {
                    targets: 0,
                    orderable: false,
                },
                {
                    targets: -1,
                    orderable: false,
                },
            ],

            language: de,

            headerCallback: function (thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                    <label class="kt-checkbox kt-checkbox--single kt-checkbox--solid">
                        <input type="checkbox" value="" class="kt-group-checkable">
                        <span></span>
                    </label>`;
            },
        });

        $('#options').on('change', '.kt-group-checkable', function () {
            var set = $(this).closest('table').find('td:first-child .kt-checkable');
            var checked = $(this).is(':checked');

            $(set).each(function () {
                if (checked) {
                    $(this).prop('checked', true).change();
                    $(this).closest('tr').addClass('active');
                } else {
                    $(this).prop('checked', false).change();
                    $(this).closest('tr').removeClass('active');
                }
            });
        });

        $('#options').on('change', 'tbody tr .kt-checkbox', function () {
            $(this).parents('tr').toggleClass('active');
        });

    };

    var initTable5 = function () {

        if ($('#matchings').length <= 0) {
            return;
        }

        let options = {
            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            order: [[2, 'asc']],

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            autoWidth: false,
            bSortCellsTop: true,


            processing: true,
            serverSide: true,
            ajax: {
                url: $('#matchings').data('source'),
            },
            columns: [
                {data: 'collapse', orderable: false},
                {data: 'type_long'},
                {data: 'name'},
                {data: 'correct_vendor_id'},
                {data: 'wrong_vendor_id'},
                {data: 'comment'},
                {data: 'licensor_name'},
                {data: 'internal_order'},
                {data: 'actions', orderable: false},
            ],

            language: de,

            "drawCallback": function () {
                initChildTable(this)
            },
        };

        var table = $('#matchings').DataTable(options);

    };

    var initTable6 = function () {

        if ($('#users').length <= 0) {
            return;
        }

        var table = $('#users').DataTable({


            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            order: [[0, 'asc']],

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            language: de,

            bSortCellsTop: true,
        });

    };

    var searchTable = function () {

        if ($('#search').length <= 0) {
            return;
        }

        var table = $('#search').DataTable({


            // DOM Layout settings
            dom: `<'row'<'col-sm-12'tr>>
			<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

            // Order settings
            ordering: false,

            lengthMenu: [10, 25, 50, 100],
            pageLength: 25,
            paging: true,

            language: de,

            bSortCellsTop: true,

            columnDefs: [
                {
                    targets: -1,
                    orderable: false,
                },
            ],

        });

    };

    var initChildTable = function (table, row) {
        $(".dataTables_wrapper").on('click', '.btn-show-childs', function () {
            row = $(this).closest("tr")[0]

            if (row.dataset.hasChilds === "true") {

                if (row.dataset.shown === "false") {
                    showChildTable($.fn.dataTable.tables({visible: true, api: true}), row)

                    row.dataset.shown = true;
                } else {
                    table.row(row).child.hide();
                    row.dataset.shown = false;
                }
            }
        })
    }


    var showChildTable = function (table, row) {
        let $child = $(row.dataset.child)

        $child.each(function () {
            let $row = $(this)
            $row.find("td").each(function () {
                if (!table.column($(this).index()).visible()) {
                    $(this).hide()
                }
            })
        })

        table.row(row).child($child).show();
    }

    var relayoutTables = function () {
        $.fn.dataTable
            .tables({visible: true, api: true})
            .columns.adjust()
            .fixedColumns().relayout();
    }

    return {

        //main function to initiate the module
        init: function () {
            initTable1();
            initTable2();
            initTable3();
            initTable4();
            initTable5();
            initTable6();
            searchTable();

            drafts();

            if ($('#ema-list')) {
                new EmaList(de).init()
            }
            if ($('#release-avails-list')) {
                new ReleaseAvailsList(de).init()
            }
            if ($('#sub-channels')) {
                new SubChannels(de).init()
            }
            if ($('#title-select-statuses')) {
                new TitleSelectStatuses(de).init()
            }
            if ($('#title-delivery-statuses')) {
                new TitleDeliveryStatuses(de).init()
            }
            if ($('#title-store-check-statuses')) {
                new TitleStoreCheckStatuses(de).init()
            }
            if ($('#title-exclusive-statuses')) {
                new TitleExclusiveStatuses(de).init()
            }
            if ($('#timing-rights')) {
                new TimingRights(de).init()
            }
            if ($('#timing-promos')) {
                new TimingPromos(de).init()
            }
            if ($('#timings-preorders')) {
                new TimingPreorders(de).init()
            }
            if ($('#dashboard-releases')) {
                new DashboardReleases(de).init()
            }
            if ($('#dashboard-endings')) {
                new DashboardEndings(de).init()
            }
            if ($('#dashboard-exclusives')) {
                new DashboardExclusives(de).init()
            }
            if ($('#public-title-exclusive-statuses')) {
                new PublicTitleExclusiveStatuses(de).init()
            }

            $("#filter-row").on("shown.bs.collapse", () => {
                setTimeout(() => {
                    relayoutTables()
                }, 100);
            })
        }
    };
}
();

$(document).ready(function () {
    KTDatatablesAdvancedColumnRendering.init();
});