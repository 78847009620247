import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  #arrows = {
    leftArrow: '<i class="la la-angle-left"></i>',
    rightArrow: '<i class="la la-angle-right"></i>'
  }

  connect() {
    $(this.element).inputmask("99.99.9999", {
      "placeholder": "DD.MM.YYYY",
    });

    $(this.element).datepicker({
      format: 'dd.mm.yyyy',
      language: 'de',
      todayHighlight: true,
      orientation: $(this.element).data('orientation') || "bottom left",
      templates: this.#arrows
    }).on('changeDate', function () {
      const event = new Event("input")
      $(this)[0].dispatchEvent(event)

      setTimeout(function () {
        $.fn.dataTable
          .tables({visible: true, api: true}).draw()
      })
    })
  }
}