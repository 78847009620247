import {Controller} from "@hotwired/stimulus"
import { transition } from "./utils/transition"


export default class extends Controller {
    static targets = []
    static values = {
        visible: {type: Boolean, default: false},
    }
    static classes = ['enter', 'enterFrom', 'enterTo', 'leave', 'leaveFrom', 'leaveTo', 'toggle']

    connect() {
        this.boundBeforeCache = this.beforeCache.bind(this)
        document.addEventListener('turbo:before-cache', this.boundBeforeCache)
    }

    disconnect() {
        document.removeEventListener('turbo:before-cache', this.boundBeforeCache)
    }

    // show if user scrolled 200 pixels down, hide if user scrolled 200 pixels up
    scroll() {
        if (window.scrollY > 215) {
            this.show()
        } else {
            this.close()
        }
    }

    toggle() {
        this.visibleValue = !this.visibleValue
    }

    show() {
        this.visibleValue = true
    }

    close() {
        this.visibleValue = false
    }

    visibleValueChanged() {
        transition(this.element, this.visibleValue, this.transitionOptions)
    }

    get transitionOptions() {
        // once the Class API default values are available, we can simplify this
        return {
            enter: this.hasEnterClass ? this.enterClasses.join(' ') : 'tw-transition tw-ease-out tw-duration-100',
            enterFrom: this.hasEnterFromClass ? this.enterFromClasses.join(' ') : 'tw-transform tw-opacity-0',
            enterTo: this.hasEnterToClass ? this.enterToClasses.join(' ') : 'tw-transform tw-opacity-100',
            leave: this.hasLeaveClass ? this.leaveClasses.join(' ') : 'tw-transition tw-ease-in tw-duration-75',
            leaveFrom: this.hasLeaveFromClass ? this.leaveFromClasses.join(' ') : 'tw-transform tw-opacity-100',
            leaveTo: this.hasLeaveToClass ? this.leaveToClasses.join(' ') : 'tw-transform tw-opacity-0',
            toggleClass: this.hasToggleClass ? this.toggleClasses.join(' ') : '!tw-hidden',
        }
    }

    beforeCache() {
        this.visibleValue = false
    }
}